"use strict";
import { Button, Dialog, Form, Input, Select, Field, Table, Message, Pagination } from "@alifd/next";
import React from "react";
import { getCloudClustersList } from "../../../../api/cluster";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { ACKClusterStatus } from "../../../../utils/common";
import { handleError } from "../../../../utils/errors";
import { locale } from "../../../../utils/locale";
class CloudServiceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.setVisible(false);
      this.props.setCloudService(false);
      this.resetField();
    };
    this.onOk = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        this.setState({ btnLoading: true });
        const { provider, accessKeyID, accessKeySecret } = values;
        const { page, pageSize } = this.state;
        const params = {
          provider,
          accessKeyID,
          accessKeySecret,
          page,
          pageSize,
          customError: true
        };
        getCloudClustersList(params).then((re) => {
          if (re) {
            this.setState({
              choseInput: false,
              cloudClusters: re.clusters,
              btnLoading: false,
              total: re.total
            });
            this.props.onPropsOK();
          }
        }).catch((err) => {
          this.setState({ btnLoading: false });
          handleError(err);
        });
      });
    };
    this.connectCloudCluster = (record) => {
      const { id = "", description = "", icon = "", name = "" } = record;
      const { accessKeyID, accessKeySecret, provider } = this.field.getValues();
      const params = {
        provider,
        accessKeyID,
        accessKeySecret,
        alias: name,
        name: id.substring(0, 16),
        description,
        clusterID: id,
        icon
      };
      new Promise((resolve) => {
        this.props.dispatch({
          type: "clusters/connectcloudCluster",
          payload: {
            params,
            resolve
          }
        });
      }).then((res) => {
        if (res) {
          Message.success({
            title: "success,connect success",
            duration: 3e3
          });
        }
      }).then(() => {
      });
    };
    this.handleChange = (pageIdx) => {
      this.setState(
        {
          page: pageIdx
        },
        () => {
          const { provider, accessKeyID, accessKeySecret } = this.field.getValues();
          const { page, pageSize } = this.state;
          const params = {
            provider,
            accessKeyID,
            accessKeySecret,
            page,
            pageSize,
            customError: true
          };
          this.setState({ tableLoading: true });
          getCloudClustersList(params).then((res) => {
            if (res) {
              this.setState({
                choseInput: false,
                cloudClusters: res.clusters,
                tableLoading: false,
                total: res.total
              });
            }
          }).catch((err) => {
            this.setState({ tableLoading: false });
            handleError(err);
          });
        }
      );
    };
    this.field = new Field(this);
    this.state = {
      page: 1,
      pageSize: 10,
      choseInput: true,
      cloudClusters: [],
      btnLoading: false,
      tableLoading: false,
      total: 0
    };
  }
  resetField() {
    this.setState({
      choseInput: true
    });
  }
  render() {
    const init = this.field.init;
    const { visible } = this.props;
    const { choseInput, cloudClusters, btnLoading, total, tableLoading } = this.state;
    const { Column } = Table;
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 8
      },
      wrapperCol: {
        span: 16
      }
    };
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "status",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster Status"),
        dataIndex: "status",
        cell: (v) => {
          const findArr = ACKClusterStatus.filter((item) => {
            return item.key == v;
          });
          return /* @__PURE__ */ React.createElement("span", { style: { color: findArr[0].color || "" } }, " ", v, " ");
        }
      },
      {
        key: "apiServerURL",
        title: /* @__PURE__ */ React.createElement(Translation, null, "API Address"),
        dataIndex: "apiServerURL",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "type",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Type"),
        dataIndex: "type",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "zone",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Zone"),
        dataIndex: "zone",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Permission, { request: { resource: "cluster:*", action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              component: "a",
              onClick: () => {
                this.connectCloudCluster(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Connect")
          ));
        }
      }
    ];
    const providerList = [{ value: "aliyun", label: "Aliyun ACK" }];
    const notice = "DataNature won't save your AK/SK and we strongly recommend allocating the smallest available permission set";
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        locale: locale().Dialog,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Connect Kubernetes Cluster From Cloud"),
        autoFocus: true,
        visible,
        onOk: this.onOk,
        onCancel: this.onClose,
        onClose: this.onClose,
        footer: choseInput && /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onOk, loading: btnLoading }, /* @__PURE__ */ React.createElement(Translation, null, "Next Step")),
        v2: true
      },
      /* @__PURE__ */ React.createElement(If, { condition: choseInput }, /* @__PURE__ */ React.createElement(Message, { style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, notice)), /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field, className: "cloud-server-wrapper" }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Provider"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          locale: locale().Select,
          mode: "single",
          size: "large",
          dataSource: providerList,
          className: "item",
          ...init("provider", {
            rules: [
              {
                required: true,
                message: /* @__PURE__ */ React.createElement(Translation, null, "content cannot be empty")
              }
            ]
          })
        }
      )), /* @__PURE__ */ React.createElement(FormItem, { label: "AccessKey", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          htmlType: "password",
          name: "accessKeyID",
          ...init("accessKeyID", {
            rules: [
              {
                required: true,
                message: /* @__PURE__ */ React.createElement(Translation, null, "content cannot be empty")
              }
            ]
          })
        }
      )), /* @__PURE__ */ React.createElement(FormItem, { label: "AccessKeySecret", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          htmlType: "password",
          name: "accessKeySecret",
          ...init("accessKeySecret", {
            rules: [
              {
                required: true,
                message: /* @__PURE__ */ React.createElement(Translation, null, "content cannot be empty")
              }
            ]
          })
        }
      )))),
      /* @__PURE__ */ React.createElement(If, { condition: !choseInput }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: cloudClusters, loading: tableLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))), /* @__PURE__ */ React.createElement(
        Pagination,
        {
          locale: locale().Pagination,
          hideOnlyOnePage: true,
          total,
          size: "small",
          className: "cluster-cloud-pagination-wrapper",
          pageSize: this.state.pageSize,
          current: this.state.page,
          onChange: this.handleChange
        }
      ))
    ));
  }
}
export default CloudServiceDialog;
